const all = [
  {
    value: 'attachment',
    label: 'attachment'
  },
  {
    value: 'attributeSticker',
    label: 'attribute sticker'
  },
  {
    value: 'biasTape',
    label: 'bias tape'
  },
  {
    value: 'buckle',
    label: 'buckle'
  },
  {
    value: 'burr',
    label: 'burr'
  },
  {
    value: 'button',
    label: 'button'
  },
  {
    value: 'buttonSpare',
    label: 'button spare'
  },
  {
    value: 'canadaFillLabel',
    label: 'canada fill label'
  },
  {
    value: 'canvasTape',
    label: 'canvas tape'
  },
  {
    value: 'capsuleHangtag',
    label: 'capsule hangtag'
  },
  {
    value: 'careLabel',
    label: 'care label'
  },
  {
    value: 'careLabel1',
    label: 'care label 1'
  },
  {
    value: 'careLabel2',
    label: 'care label 2'
  },
  {
    value: 'careLabel3',
    label: 'care label 3'
  },
  {
    value: 'careLabel4',
    label: 'care label 4'
  },
  {
    value: 'careLabel5',
    label: 'care label 5'
  },
  {
    value: 'careLabel5Japan',
    label: 'care label 5 japan'
  },
  {
    value: 'careLabel5Lse',
    label: 'care label 5 lse'
  },
  {
    value: 'careLabel6',
    label: 'care label 6'
  },
  {
    value: 'careLabel7',
    label: 'care label 7'
  },
  {
    value: 'careLabel8',
    label: 'care label 8'
  },
  {
    value: 'careLabel9',
    label: 'care label 9'
  },
  {
    value: 'careLabelMexico',
    label: 'care label mexico'
  },
  {
    value: 'careLabelQuad',
    label: 'care label quad'
  },
  {
    value: 'collarStay',
    label: 'collar stay'
  },
  {
    value: 'C',
    label: 'contrast fabric'
  },
  {
    value: 'D',
    label: 'contrast fabric 2'
  },
  {
    value: 'cooLabel',
    label: 'coo label'
  },
  {
    value: 'cordStopper',
    label: 'cordstopper'
  },
  {
    value: 'CrossSource',
    label: 'cross source'
  },
  {
    value: 'crossSource2',
    label: 'cross source 2'
  },
  {
    value: 'finishCrossSource',
    label: 'CROSS SOURCE FINISH'
  },
  {
    value: 'disclaimerHangtag',
    label: 'disclaimer hangtag'
  },
  {
    value: 'disclaimerHangtag2',
    label: 'disclaimer hangtag 2'
  },
  {
    value: 'disclaimerHangtag3',
    label: 'disclaimer hangtag 3'
  },
  {
    value: 'drawcord',
    label: 'drawcord'
  },
  {
    label: 'elastic',
    value: 'elastic'
  },
  {
    value: 'embroidery',
    label: 'embroidery'
  },
  {
    value: 'embroideryThread',
    label: 'embroidery thread'
  },
  {
    value: 'envelope',
    label: 'envelope'
  },
  {
    value: 'eyelet',
    label: 'eyelet'
  },
  {
    value: 'facing',
    label: 'facing'
  },
  {
    value: 'fastener',
    label: 'fastener'
  },
  {
    value: 'featureHangtag',
    label: 'feature hangtag'
  },
  {
    value: 'featureHangtag2',
    label: 'feature hangtag 2'
  },
  {
    value: 'featureHangtag3',
    label: 'feature hangtag 3'
  },
  {
    value: 'featureHangtag4',
    label: 'feature hangtag 4'
  },
  {
    value: 'fill',
    label: 'fill'
  },
  {
    value: 'finish',
    label: 'finish'
  },
  {
    value: 'fitLabel',
    label: 'fit label'
  },
  {
    value: 'flagLabel',
    label: 'flag label'
  },
  {
    value: 'flasher',
    label: 'flasher'
  },
  {
    value: 'foldingVAS',
    label: 'folding vas'
  },
  {
    value: 'fusible',
    label: 'fusible'
  },
  {
    value: 'heatTransfer',
    label: 'heat transfer'
  },
  {
    value: 'heatTransfer2',
    label: 'heat transfer 2'
  },
  {
    value: 'heatTransferCanada',
    label: 'heat transfer canada'
  },
  {
    value: 'hookAndEye',
    label: 'hook and eye'
  },
  {
    value: 'hookAndLoop',
    label: 'hook and loop'
  },
  {
    value: 'hopiBurr',
    label: 'hopi burr'
  },
  {
    value: 'interlining',
    label: 'interlining'
  },
  {
    value: 'lTab',
    label: 'l tab'
  },
  {
    value: 'label',
    label: 'label'
  },
  {
    value: 'label501Batwing',
    label: 'label 501 batwing'
  },
  {
    value: 'labelPremium',
    label: 'label premium'
  },
  {
    value: 'leatherLikeLabel',
    label: 'leather like label'
  },
  {
    value: 'leatherPatch',
    label: 'leather patch'
  },
  {
    value: 'lining',
    label: 'lining'
  },
  {
    value: 'mainHangtag',
    label: 'main hangtag'
  },
  {
    value: 'mainLabel',
    label: 'main label'
  },
  {
    value: 'matchbook',
    label: 'matchbook'
  },
  {
    value: 'miscellaneous',
    label: 'miscellaneous'
  },
  {
    value: 'otherHangtag',
    label: 'other hangtag'
  },
  {
    value: 'patch',
    label: 'patch'
  },
  {
    value: 'patchVerbiage',
    label: 'patch verbiage'
  },
  {
    value: 'performanceHangtag',
    label: 'performance hangtag'
  },
  {
    value: 'performanceHeatTransfer',
    label: 'performance heat transfer'
  },
  {
    value: 'performancePrint',
    label: 'performance print'
  },
  {
    value: 'piping',
    label: 'piping'
  },
  {
    value: 'pocketing',
    label: 'pocketing'
  },
  {
    value: 'pocketing2',
    label: 'pocketing 2'
  },
  {
    value: 'pocketing3',
    label: 'pocketing 3'
  },
  {
    value: 'pocketing4',
    label: 'pocketing 4'
  },
  {
    value: 'polybagVAS',
    label: 'polybag vas'
  },
  {
    value: 'press',
    label: 'press'
  },
  {
    value: 'A',
    label: 'primary fabric'
  },
  {
    value: 'primaryFinish',
    label: 'primary finish'
  },
  {
    value: 'printedLabel',
    label: 'printed label'
  },
  {
    value: 'qLoop',
    label: 'q-loop'
  },
  {
    value: 'removeVAS',
    label: 'remove vas'
  },
  {
    value: 'repairThread',
    label: 'repair thread'
  },
  {
    value: 'rib',
    label: 'rib'
  },
  {
    value: 'rivet',
    label: 'rivet'
  },
  {
    value: 'screenPrint',
    label: 'screenprint'
  },
  {
    value: 'screenPrint2',
    label: 'screenprint 2'
  },
  {
    value: 'screenPrintCanada',
    label: 'screenprint canada'
  },
  {
    value: 'secondaryFinish',
    label: 'secondary finish'
  },
  {
    value: 'shank',
    label: 'shank'
  },
  {
    value: 'shank2',
    label: 'shank 2'
  },
  {
    value: 'shankFastener',
    label: 'shank fastener'
  },
  {
    value: 'sizeLabel',
    label: 'size label'
  },
  {
    value: 'sizeSticker',
    label: 'size sticker'
  },
  {
    value: 'snap',
    label: 'snap'
  },
  {
    value: 'stud',
    label: 'stud'
  },
  {
    value: 'tab',
    label: 'tab'
  },
  {
    value: 'tape',
    label: 'tape'
  },
  {
    value: 'thread',
    label: 'thread'
  },
  {
    value: 'uvmFlasher',
    label: 'uvm flasher'
  },
  {
    value: 'uvmHangtag',
    label: 'uvm hangtag'
  },
  {
    value: 'uvmMatchbook',
    label: 'uvm matchbook'
  },
  {
    value: 'uvmSticker',
    label: 'uvm sticker'
  },
  {
    value: 'zipper',
    label: 'zipper'
  },
  {
    value: 'zipper2',
    label: 'zipper 2'
  },
  {
    value: 'zipperPull',
    label: 'zipper pull'
  },
  {
    value: 'zipperTape',
    label: 'zipper tape'
  }
]

export default all

export const lookups = all.reduce((acc, component) => {
  return {
    ...acc,
    [component.value]: component.label
  }
}, {})
