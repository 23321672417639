const all = [{
  value: 'allOver',
  label: 'all over'
}, {
  value: 'arcuate',
  label: 'arcuate'
}, {
  value: 'back',
  label: 'back'
}, {
  value: 'backNeck',
  label: 'back neck'
}, {
  value: 'backPocketWelt',
  label: 'back pocket welt'
}, {
  value: 'beltLoop',
  label: 'belt loop'
}, {
  value: 'button',
  label: 'button'
}, {
  value: 'button1',
  label: 'button 1'
}, {
  value: 'button2',
  label: 'button 2'
}, {
  value: 'button3',
  label: 'button 3'
}, {
  value: 'centerBack',
  label: 'center back'
}, {
  value: 'centerFront',
  label: 'center front'
}, {
  value: 'cfPlacket',
  label: 'CF placket'
}, {
  value: 'chest',
  label: 'chest'
}, {
  value: 'coinPocketWelt',
  label: 'coin pocket welt'
}, {
  value: 'collar',
  label: 'collar'
}, {
  value: 'collarBand',
  label: 'collar band'
}, {
  value: 'contrastButton',
  label: 'contrast button'
}, {
  value: 'cuff',
  label: 'cuff'
}, {
  value: 'embroidery',
  label: 'embroidery'
}, {
  value: 'fly',
  label: 'fly'
}, {
  value: 'front',
  label: 'front'
}, {
  value: 'frontPocketFlap',
  label: 'front pocket flap'
}, {
  value: 'gusset',
  label: 'gusset'
}, {
  value: 'hangerLoop',
  label: 'hanger loop'
}, {
  value: 'hem',
  label: 'hem'
}, {
  value: 'hiddenPocket',
  label: 'hidden pocket'
}, {
  value: 'hood',
  label: 'hood'
}, {
  value: 'hookAndEye',
  label: 'hook and eye'
}, {
  value: 'interior',
  label: 'interior'
}, {
  value: 'internal',
  label: 'internal'
}, {
  value: 'internalWaistband',
  label: 'internal waistband'
}, {
  value: 'label',
  label: 'label'
}, {
  value: 'leftChest',
  label: 'left chest'
}, {
  value: 'leftSideSeam',
  label: 'left side seam'
}, {
  value: 'lockerLoop',
  label: 'locker loop'
}, {
  value: 'loop',
  label: 'loop'
}, {
  value: 'mainBody',
  label: 'main body'
}, {
  value: 'neck',
  label: 'neck'
}, {
  value: 'patch',
  label: 'patch'
}, {
  value: 'placket',
  label: 'placket'
}, {
  value: 'pocket',
  label: 'pocket'
}, {
  value: 'pocketBack',
  label: 'pocket back'
}, {
  value: 'pocketFlap',
  label: 'pocket flap'
}, {
  value: 'pocketWelt',
  label: 'pocket welt'
}, {
  value: 'position1',
  label: 'position 1'
}, {
  value: 'position2',
  label: 'position 2'
}, {
  value: 'position3',
  label: 'position 3'
}, {
  value: 'position4',
  label: 'position 4'
}, {
  value: 'position5',
  label: 'position 5'
}, {
  value: 'position6',
  label: 'position 6'
}, {
  value: 'position7',
  label: 'position 7'
}, {
  value: 'position8',
  label: 'position 8'
}, {
  value: 'position9',
  label: 'position 9'
}, {
  value: 'position10',
  label: 'position 10'
}, {
  value: 'position11',
  label: 'position 11'
}, {
  value: 'position12',
  label: 'position 12'
}, {
  value: 'position13',
  label: 'position 13'
}, {
  value: 'position14',
  label: 'position 14'
}, {
  value: 'position15',
  label: 'position 15'
}, {
  value: 'position16',
  label: 'position 16'
}, {
  value: 'position17',
  label: 'position 17'
}, {
  value: 'position18',
  label: 'position 18'
}, {
  value: 'position19',
  label: 'position 19'
}, {
  value: 'position20',
  label: 'position 20'
}, {
  value: 'rocap',
  label: 'rocap'
}, {
  value: 'shell',
  label: 'shell'
}, {
  value: 'shoulder',
  label: 'shoulder'
}, {
  value: 'side',
  label: 'side'
}, {
  value: 'sleeve',
  label: 'sleeve'
}, {
  value: 'spareButton',
  label: 'spare button'
}, {
  value: 'splitHem',
  label: 'split hem'
}, {
  value: 'waistband',
  label: 'waistband'
}, {
  value: 'yoke',
  label: 'yoke'
}]

export default all

export const lookups = all.reduce((acc, location) => {
  return {
    ...acc,
    [ location.value ]: location.label
  }
}, {})
