const sort = {
  A: 1,
  CrossSource: 2,
  crossSource2: 3,
  C: 4,
  D: 5,
  rib: 6,
  tape: 7,
  piping: 8,
  biasTape: 9,
  facing: 10,
  lining: 11,
  qLoop: 16,
  miscellaneous: 17
}

const components = Object.keys(sort)

const locations = [
  'back',
  'backNeck',
  'beltLoop',
  'centerBack',
  'centerFront',
  'cfPlacket',
  'chest',
  'collar',
  'collarBand',
  'cuff',
  'fly',
  'front',
  'gusset',
  'hem',
  'hood',
  'interior',
  'internal',
  'internalWaistband',
  'leftChest',
  'leftSideSeam',
  'lockerLoop',
  'loop',
  'mainBody',
  'neck',
  'placket',
  'pocket',
  'pocketWelt',
  'shell',
  'shoulder',
  'side',
  'sleeve',
  'waistband',
  'yoke'
]

export default {
  components,
  locations,
  sort
}
