export default [{
  'value': '1001',
  'label': 'Alternative Lengths'
}, {
  'value': '1004',
  'label': 'Jeans'
}, {
  'value': '1005',
  'label': 'Outerwear'
}, {
  'value': '1006',
  'label': 'Polos'
}, {
  'value': '1009',
  'label': 'Shorts'
}, {
  'value': '1010',
  'label': 'Skirts'
}, {
  'value': '1013',
  'label': 'Sweaters'
}, {
  'value': '1014',
  'label': 'Sweatshirts'
}, {
  'value': '1016',
  'label': 'Tees'
}, {
  'value': '1018',
  'label': 'Truckers'
}, {
  'value': '1020',
  'label': 'Woven Tops'
}, {
  'value': '1027',
  'label': 'Dresses'
}, {
  'value': '1033',
  'label': 'Khakis'
}, {
  'value': '1034',
  'label': 'Other Pants'
}, {
  'value': '1041',
  'label': 'Bodywear Tops'
}, {
  'value': '1042',
  'label': 'Bodywear Bottoms'
}]
