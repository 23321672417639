module.exports = {
  // Levis
  '483': {
    // Mens
    '663': {
      '674': require('./mens/bottoms.js'),
      '673': require('./mens/tops.js')
    },
    // Womens
    '664': {
      '674': require('./womens/bottoms.js'),
      '673': require('./womens/tops.js')
    }
  },

  // Dockers
  '482': {
    // Mens
    '663': {
      '674': require('./mens/bottoms.js'),
      '673': require('./mens/tops.js')
    },
    // Womens
    '664': {
      '674': require('./womens/bottoms.js'),
      '673': require('./womens/tops.js')
    }
  },

  // Signature
  '276': {
    // Mens
    '663': {
      '674': require('./mens/bottoms.js'),
      '673': require('./mens/tops.js')
    },
    // Womens
    '664': {
      '674': require('./womens/bottoms.js'),
      '673': require('./womens/tops.js')
    }
  },

  // Denizen
  '489': {
    // Mens
    '663': {
      '674': require('./mens/bottoms.js'),
      '673': require('./mens/tops.js')
    },
    // Womens
    '664': {
      '674': require('./womens/bottoms.js'),
      '673': require('./womens/tops.js')
    }
  },
  allClasses: require('./allClasses.js').default,
  allSubclasses: require('./allSubclasses.js').default
}
